import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TextHighlighterComponent from './TextHighlighterComponent'; // นำเข้า component ของคุณ
import TextHighlighterEngComponent from './TextHighlighterComponentEng'; // นำเข้า component ของคุณ
import HomePage from './HomePage'; // นำเข้า component หน้าหลัก (ถ้ามี)

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} /> {/* หน้าหลักหรือหน้าอื่น ๆ */}
        <Route path="/text-highlighter-th" element={<TextHighlighterComponent />} /> {/* Path สำหรับ TextHighlighterComponent */}
        <Route path="/text-highlighter-eng" element={<TextHighlighterEngComponent />} /> {/* เพิ่ม Route อื่น ๆ ตามต้องการ */}
      </Routes>
    </Router>
  );
}

export default App;
