import React from 'react';
import { Container, Typography, Button, Box, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import HighlightIcon from '@mui/icons-material/Highlight';

const HomePage = () => {
  return (
    <Container
      maxWidth="lg"
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        background: 'linear-gradient(to right, #f8f9fa, #e9ecef)',
        padding: '2rem',
      }}
    >
      <Box
        style={{
          backgroundColor: '#fff',
          padding: '3rem',
          borderRadius: '15px',
          boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
          maxWidth: '800px',
          width: '100%',
        }}
      >
        <HighlightIcon style={{ fontSize: '4rem', color: '#ff8a00', marginBottom: '1rem' }} />
        <Typography variant="h3" style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
          Welcome to SDG Key Phrase Highlighter
        </Typography>
        <Typography variant="h6" style={{ marginBottom: '2rem', color: '#6c757d' }}>
          Highlight your text with key phrases related to Sustainable Development Goals (SDGs).
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="center">
          <Link to="/text-highlighter-th" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                padding: '0.75rem 2rem',
                fontSize: '1.25rem',
                borderRadius: '10px',
                background: 'linear-gradient(90deg, #1e90ff, #00bfff)', // สีฟ้าสำหรับ SDG ภาษาไทย
                color: '#fff',
                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'scale(1.05)';
                e.currentTarget.style.boxShadow = '0px 12px 20px rgba(0, 0, 0, 0.3)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
                e.currentTarget.style.boxShadow = '0px 8px 15px rgba(0, 0, 0, 0.2)';
              }}
            >
              SDG ไทย
            </Button>
          </Link>
          <Link to="/text-highlighter-eng" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              color="primary"
              style={{
                padding: '0.75rem 2rem',
                fontSize: '1.25rem',
                borderRadius: '10px',
                background: 'linear-gradient(90deg, #ff8a00, #e52e71)', // สีส้ม/ชมพูสำหรับ SDG ภาษาอังกฤษ
                color: '#fff',
                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'scale(1.05)';
                e.currentTarget.style.boxShadow = '0px 12px 20px rgba(0, 0, 0, 0.3)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
                e.currentTarget.style.boxShadow = '0px 8px 15px rgba(0, 0, 0, 0.2)';
              }}
            >
              SDG English
            </Button>
          </Link>
        </Stack>
      </Box>
    </Container>
  );
};

export default HomePage;
