import './TextHighlighter.css';
import React, { useState, useCallback, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardActions, Checkbox, TextField, Button, Grid, Typography, Box } from '@mui/material';
import HighlightIcon from '@mui/icons-material/Highlight';

const TextHighlighterEngComponent = () => {
  const [text, setText] = useState('');
  const [highlightedText, setHighlightedText] = useState('');
  const [showSDG16, setShowSDG16] = useState(true);
  const [sdg17Keywords, setSdg17Keywords] = useState('');
  const [showOutput, setShowOutput] = useState(false);
  const [sdgKeywordsArray, setSdgKeywordsArray] = useState([]);
  const [highlightedSDGs, setHighlightedSDGs] = useState([]);

  const colorMap = {
    1: 'c1',
    2: 'c2',
    3: 'c3',
    4: 'c4',
    5: 'c5',
    6: 'c6',
    7: 'c7',
    8: 'c8',
    9: 'c9',
    10: 'c10',
    11: 'c11',
    12: 'c12',
    13: 'c13',
    14: 'c14',
    15: 'c15',
    16: 'c16',
    17: 'c17',
    18: 'c18',
  };

  const sdgData = [
    { number: 1, name: "No Poverty", className: colorMap[1] },
    { number: 2, name: "Zero Hunger", className: colorMap[2] },
    { number: 3, name: "Good Health and Well-being", className: colorMap[3] },
    { number: 4, name: "Quality Education", className: colorMap[4] },
    { number: 5, name: "Gender Equality", className: colorMap[5] },
    { number: 6, name: "Clean Water and Sanitation", className: colorMap[6] },
    { number: 7, name: "Affordable and Clean Energy", className: colorMap[7] },
    { number: 8, name: "Decent Work and Economic Growth", className: colorMap[8] },
    { number: 9, name: "Industry, Innovation, and Infrastructure", className: colorMap[9] },
    { number: 10, name: "Reduced Inequality", className: colorMap[10] },
    { number: 11, name: "Sustainable Cities and Communities", className: colorMap[11] },
    { number: 12, name: "Responsible Consumption and Production", className: colorMap[12] },
    { number: 13, name: "Climate Action", className: colorMap[13] },
    { number: 14, name: "Life Below Water", className: colorMap[14] },
    { number: 15, name: "Life on Land", className: colorMap[15] },
    { number: 16, name: "Peace, Justice, and Strong Institutions", className: colorMap[16] },
    { number: 17, name: "Partnerships for the Goals", className: colorMap[17] },
    { number: 18, name: "Other", className: colorMap[18] },
  ];

  const highlightKeywords = useCallback(() => {
    let highlightedOutput = text;
    let highlightedSDGsTemp = [];

    if (showSDG16) {
      sdgKeywordsArray.forEach(sdgData => {
        let matched = false;
        sdgData.keywords.forEach((keyword) => {
          const regex = new RegExp(`${keyword}`, 'gi');
          if (regex.test(text)) {
            matched = true;
          }
          highlightedOutput = highlightedOutput.replace(
            regex,
            (match) => `<span class="${sdgData.color}" title="SDG${sdgData.sdg}">${match}</span>`
          );
        });
        if (matched) {
          highlightedSDGsTemp.push(sdgData.sdg);
        }
      });
    }

    if (sdg17Keywords.trim()) {
      const customKeywords = sdg17Keywords.split(',').map(kw => kw.trim()).filter(kw => kw);
      customKeywords.forEach((keyword) => {
        const regex = new RegExp(`${keyword}`, 'gi');
        if (regex.test(text)) {
          highlightedSDGsTemp.push(18); // Assuming SDG18 represents custom keywords
        }
        highlightedOutput = highlightedOutput.replace(
          regex,
          (match) => `<span class="c18" title="อื่นๆ: ${keyword}">${match}</span>`
        );
      });
    }

    setHighlightedText(highlightedOutput);
    setHighlightedSDGs(highlightedSDGsTemp);
    setShowOutput(true);
  }, [text, sdg17Keywords, showSDG16, sdgKeywordsArray]);

  useEffect(() => {
    if (sdgKeywordsArray.length > 0) {
      highlightKeywords();
    }
  }, [sdgKeywordsArray, highlightKeywords]);

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleShowSDG16Change = (e) => {
    setShowSDG16(e.target.checked);
  };

  const handleSdg17KeywordsChange = (e) => {
    setSdg17Keywords(e.target.value);
  };

  const handleSubmit = () => {
    // Clear the previous output
    setHighlightedText('');
    setShowOutput(false);
    setHighlightedSDGs([]);

    fetch("https://sdg-app-ar5e.vercel.app/process_en_text", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text: text }),
    })
    .then(response => response.json())
    .then(data => {
      const newSdgKeywordsArray = Object.keys(data.sdg_keywords).map(sdgNumber => ({
        sdg: parseInt(sdgNumber, 10),
        color: colorMap[parseInt(sdgNumber, 10)],
        keywords: data.sdg_keywords[sdgNumber],
      }));
      setSdgKeywordsArray(newSdgKeywordsArray);
    })
    .catch(error => console.error("Error submitting text:", error));
  };

  return (
    <Card style={{ maxWidth: '1200px', margin: '2rem auto', padding: '1rem', boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)', borderRadius: '15px' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box style={{ paddingRight: '1rem', borderRight: '1px solid #ddd', height: '100%' }}>
            {sdgData.map((sdg) => (
              <Box display="flex" alignItems="center" key={sdg.number} style={{ marginBottom: '1rem' }}>
                <Box
                  className={sdg.className}
                  sx={{ width: 24, height: 24, marginRight: 2, borderRadius: '50%' }}
                />
                <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '1rem' }}>{`SDG ${sdg.number}: ${sdg.name}`}</Typography>
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <CardHeader
            title={
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{
                  background: 'linear-gradient(to right, #833ab4, #fd1d1d, #fcb045)',
                  borderRadius: '10px',
                  padding: '0.75rem 1.5rem',
                  boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease-in-out',
                }}
              >
                <HighlightIcon style={{ marginRight: '0.75rem', fontSize: '2.5rem', color: '#fff' }} />
                <Typography variant="h5" style={{ fontWeight: 'bold', color: '#fff', textShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)' }}>
                  SDG Key Phrase (English)
                </Typography>
              </Box>
            }
            style={{
              textAlign: 'center',
              backgroundColor: 'transparent',
              padding: '0',
              borderRadius: '10px',
            }}
          />
          <CardContent>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              value={text}
              onChange={handleTextChange}
              placeholder="Enter text to highlight"
              style={{ marginBottom: '1.5rem', fontSize: '1rem', backgroundColor: '#f8f8f8', borderRadius: '10px' }}
            />
            <div style={{ marginBottom: '1.5rem', textAlign: 'left' }}>
              <Checkbox
                checked={showSDG16}
                onChange={handleShowSDG16Change}
                color="primary"
              />
              <span>Show SDG 1-17 Highlights</span>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              value={sdg17Keywords}
              onChange={handleSdg17KeywordsChange}
              placeholder="Add keywords for SDG keyword (separate by commas)"
              style={{ marginBottom: '1.5rem', fontSize: '1rem', backgroundColor: '#f8f8f8', borderRadius: '10px' }}
            />
            <Button
              variant="contained"
              onClick={handleSubmit}
              style={{
                width: '100%',
                padding: '1rem',
                fontSize: '1.25rem',
                marginBottom: '1.5rem',
                background: 'linear-gradient(90deg, #ff8a00, #e52e71)',
                color: '#fff',
                borderRadius: '10px',
                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.2)',
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = 'scale(1.05)';
                e.currentTarget.style.boxShadow = '0px 12px 20px rgba(0, 0, 0, 0.3)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = 'scale(1)';
                e.currentTarget.style.boxShadow = '0px 8px 15px rgba(0, 0, 0, 0.2)';
              }}
            >
              SUBMIT
            </Button>

            <Box display="flex" alignItems="center" style={{ marginTop: '1.5rem' }}>
              <Typography 
                variant="body2" 
                style={{ 
                  fontWeight: 'bold', 
                  fontSize: '1rem', 
                  marginRight: '0.5rem', 
                  alignSelf: 'flex-start',
                }}
              >
                Tag:
              </Typography>
              <Box display="flex" flexWrap="wrap">
                {sdgData.map((sdg) => (
                  <Box
                    key={sdg.number}
                    className={highlightedSDGs.includes(sdg.number) ? sdg.className : 'c-gray'}
                    sx={{ 
                      padding: '0.25rem 0.75rem',
                      marginRight: '0.3rem',
                      marginBottom: '0.3rem',
                      borderRadius: '5px',
                      color: '#fff',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      fontSize: '0.75rem',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: highlightedSDGs.includes(sdg.number) ? null : '#888', 
                      },
                    }}
                  >
                    {`SDG${sdg.number}`}
                  </Box>
                ))}
              </Box>
            </Box>

            {showOutput && (
              <div
                style={{
                  padding: '1.5rem',
                  border: '1px solid #ddd',
                  borderRadius: '10px',
                  backgroundColor: '#f9f9f9',
                  minHeight: '120px',
                  fontSize: '1rem',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                  textAlign: 'left',
                }}
                dangerouslySetInnerHTML={{ __html: highlightedText }}
              />
            )}
          </CardContent>
          <CardActions style={{ justifyContent: 'center' }}>
            <p style={{ fontSize: '0.85rem', color: '#777' }}>Powered by SDG Keyword Matching</p>
          </CardActions>
        </Grid>
      </Grid>
    </Card>
  );
};

export default TextHighlighterEngComponent;
